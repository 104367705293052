<template>
  <div class="home">
    <div class="login">
      <div class="flex">
        <div class="loginBg"></div>
        <div class="loginBox">
          <h1>
            <router-link to="/">
              <img src="@/assets/images/logo.png" alt="" />
            </router-link>
          </h1>
          <!-- <h2>로그인</h2> -->
          <div class="form">
            <p>이메일(아이디)</p>
            <input type="text" v-model="email" />
            <p>비밀번호</p>
            <div class="password">
              <input :type="type" v-model="password" @keyup.enter="submit" />
            </div>

            <button class="point large" @click="submit">로그인</button>
            <div v-if="error" class="error">
              가입하지 않은 이메일 혹은 잘못된 비밀번호입니다.
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="copy">Copyright&copy; ThePlanner All Rights Resrved.</p>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { login } from "@/api/index";
export default {
  mixins: [format],
  data() {
    return {
      pwOn: false,
      type: "password",
      email: "",
      password: "",
      error: false,
    };
  },
  methods: {
    submit() {
      if (this.email == "") {
        this.$alert("이메일을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.password == "") {
        this.$alert("비밀번호를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      }
      let data = {
        email: this.email,
        password: this.password,
      };
      login(data).then(async (res) => {
        if (res.data.status == 200) {
          await this.$store.dispatch("LOGIN", res.data);

          let adminType = localStorage.getItem("adminType");
          if (adminType == "PLANNER" || adminType == "ADVISOR") {
            document.location.href = "/admin/users";
          } else if (adminType == "MANAGER") {
            document.location.href = "/admin/placemanage";
          } else {
            document.location.href = "/admin/dashboard";
          }
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
